import React,{useEffect} from "react"
import sunbrust from './sunbrustjs/index'

const fieldsMapping = {
  children: [
    {
      key: "languages",
      name: "nyelvek",
      field: "key",
      count: false,
      children: [
        {
          key: "appearance",
          name: "megjelenés éve",
          field: "key_as_string",
          count: false,
          children: [
            {
              key: "authors",
              name: "szerzők",
              field: "key",
              count: false,
              children: [
                {
                  key: "titles",
                  name: "mű címe",
                  field: "key",
                  count: true,
                  children: [
                    {
                      key: "elasticid",
                      name: "mű címe",
                      field: "key",
                      count: true,
                      children: [
                        {
                          key: "elasticid",
                          name: "mű címe",
                          field: "key",
                          children: null,
                          count: true,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

const Sunbrust = props => {
  const {data} = props

  useEffect(() => {
    sunbrust({
      fieldsMapping: fieldsMapping,
      data,
      title: "asd",
      targetId: "first",
      targetTitle: "asdTitle",
    })
  },[data])

  return (
    <div
      id="first"
      style={{
        width: "70%",
        margin: "0 auto",
      }}
    ></div>
  )
}

export default Sunbrust
