import React, { useMemo, useState } from "react"
import { Link } from "gatsby"
import {
  Container,
  Grid,
  Box,
  Breadcrumbs,
  Button,
  Typography,
  CircularProgress,
  Snackbar
} from "@material-ui/core"
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider"
import {
  Handle,
  Track,
  Tick,
} from "../components/VisualisationComponents/VisualisationComponents"

import VisualisationLayout from "../layouts/visualisation_layout"
import Head from "../components/head"
import Sunbrust from "../components/Sunbrust/Sunbrust"
import ErrorSnackbar from '../components/ErrorSnackbar/ErrorSnackbar';

import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"

import styles from "./index.module.scss"
import visualisationStyles from "./visualisation.module.scss"

const GET_YEAR_TO_YEAR_DATA_QUERY = gql`
  query Query($fromYear: Int!, $toYear: Int!) {
    elasticVisualiseByYearInterval(where: { from: $fromYear, to: $toYear }) {
      visualisePayload
    }
  }
`

const Visualisation = () => {
  const [yearValue, setYearValue] = useState([1495, 1500])
  const [fromYear, setFromYear] = useState(1495)
  const [toYear, setToYear] = useState(1500)
  const [failure, setFailure] = useState({
    error: false,
    message: ''
  })

  const tooManyDataErrorMessage = 'Az adat mennyiség túl nagy! Csökkentenie kell a két évszám között a különbséget!'
  const noDataErrorMessage = 'Ebben a tartományban nincs adat az adatbázisban! Kérem válasszon más időtartományt az évszámválasztóban!'
  const pageTitle = "Vizualizáció"

  const [domain, setDomain] = useState([1000, new Date().getFullYear()])
  const [maxZoom, setMaxZoom] = useState(false)
  const [minZoom, setMinZoom] = useState(true)
  const [zoomPhase, setZoomPhase] = useState([
    {
      phase: 0,
      value: 0,
      active: true
    },
    {
      phase: 1,
      value: 100,
      active: false
    },
    {
      phase: 2,
      value: 50,
      active: false
    },
    {
      phase: 3,
      value: 25,
      active: false
    }
  ])

  //onst [sunbrustElement, setSunbrustElement] = useState(<Sunbrust data={{}} />)
  const [sunbrustData,setSunbrustData] = useState(null)

  const sliderStyle = {
    margin: "5%",
    position: "relative",
    width: "90%",
  }

  const railStyle = {
    position: "absolute",
    width: "100%",
    height: 14,
    borderRadius: 7,
    cursor: "pointer",
    backgroundColor: "rgba(232,185,107,1)",
  }

  const { data, error, loading } = useQuery(GET_YEAR_TO_YEAR_DATA_QUERY, {
    variables: {
      fromYear,
      toYear,
    },
  })

  useMemo(() => {
    const onCompleted = data => {
      const { visualisePayload } = data.elasticVisualiseByYearInterval
      setSunbrustData(visualisePayload)

      if (visualisePayload._shards.failures) {
        setFailure({
          error: true,
          message: tooManyDataErrorMessage
        })
      }
      else if (visualisePayload.aggregations.languages.buckets.length === 0) {
        setFailure({
          error: true,
          message: noDataErrorMessage
        })
      }
      else {
        setFailure({...failure, error: false })
      }
    }
    const onError = error => {
      setSunbrustData(null)

      //SNACKBAR
      if (failure.message === '') {
        if (error.graphQLErrors[0]) {
          if (error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && error.message.includes('internal server error')) {
            setFailure({ error: true, message: 'Az adatlap jelenleg nem elérhető, kérjük próbálja meg később.' })
          }
          else if (error.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT' && error.message.includes('data too large')) {
            setFailure({ error: true, message: 'A lekérdezett adatmennyiség túl nagy!' })
          }
          else if (error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && error.message.includes('Too many requests')) {
            setFailure({ error: true, message: 'Ön elérte az egy percen belül indítható maximális bejelentkezési kísérlet számát, kérjük próbálkozzon újra egy perc múlva.' })
          }
        }
        else {
          setFailure({ error: true, message: 'Hálózati hiba' })
        }
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data)
      } else if (onError && !loading && error) {
        onError(error)
      }
    }
  }, [data, loading, error])

  const closeErrorModalHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailure({ ...failure, error: false })
  }

  const getDataButtonClickedHandler = () => {
    setFromYear(yearValue[0])
    setToYear(yearValue[1])
    setFailure({error: false, message: ''})
  }

  const onIncreaseZoomButtonClickHandler = () => {
    const findActivePhase = zoomPhase.find(phase => phase.active)

    setDomain([yearValue[0] - zoomPhase[findActivePhase.phase + 1].value, yearValue[1] + zoomPhase[findActivePhase.phase + 1].value])

    let updatedZoomPhase = zoomPhase
    updatedZoomPhase[findActivePhase.phase].active = false
    updatedZoomPhase[findActivePhase.phase + 1].active = true

    setZoomPhase(updatedZoomPhase)
    setMinZoom(false)

    if (findActivePhase.phase + 1 === 3) {
      setMaxZoom(true)
    }
  }

  const onDecreaseZoomButtonClicked = () => {
    const findActivePhase = zoomPhase.find(phase => phase.active)

    let updatedZoomPhase = zoomPhase

    setDomain([yearValue[0] - zoomPhase[findActivePhase.phase - 1].value, yearValue[1] + zoomPhase[findActivePhase.phase - 1].value])

    updatedZoomPhase[findActivePhase.phase].active = false
    updatedZoomPhase[findActivePhase.phase - 1].active = true
    setZoomPhase(updatedZoomPhase)

    setMaxZoom(false)

    if (findActivePhase.phase - 1 === 0) {
      setDomain([1000, new Date().getFullYear()])
      updatedZoomPhase[findActivePhase.phase].active = false
      updatedZoomPhase[findActivePhase.phase - 1].active = true
      setZoomPhase(updatedZoomPhase)

      setMinZoom(true)
    }
  }

  return (
    <React.Fragment>
      <VisualisationLayout>
        <Head title={`${pageTitle}`} />
        <Grid
          className={styles.mainContainer}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Box className={styles.topContainerSubpage} component="section">
            <Container
              className={styles.topContainerInnerSubpage}
              maxWidth={"lg"}
            >
              <Box />
            </Container>
          </Box>
          <Box
            className={styles.pageContentContainerSubpageWhite}
            component="section"
          >
            <Container maxWidth={"lg"}>
              <Box className={styles.breadcrumbContainerSubpage} component="div">
                <Breadcrumbs separator="/" aria-label="breadcrumb">
                  <Link hinputRef="/" className={styles.breadcrumbMenuLink}>
                    Főoldal
                </Link>
                  <span className={styles.breadcrumbMenuNow}>
                    {`${pageTitle}`}
                  </span>
                </Breadcrumbs>
              </Box>
              <div className={styles.textContentContainer}>
                <h3>{`${pageTitle}`}</h3>
                <Grid container justify="center">
                  <Typography
                    id="range-slider"
                    gutterBottom
                    style={{
                      fontFamily: "'Lora', serif",
                      fontSize: "18px",
                      marginBottom: "1rem",
                    }}
                  >
                    Évszám választó
                  <br />
                    {`${yearValue[0]} -tól ${yearValue[1]} -ig`}
                  </Typography>

                  <div style={{ height: 120, width: "100%" }}>
                    <Slider
                      mode={1}
                      step={1}
                      domain={domain}
                      rootStyle={sliderStyle}
                      onChange={v => setYearValue(v)}
                      values={yearValue}
                    >
                      <Rail>
                        {({ getRailProps }) => (
                          <div style={railStyle} {...getRailProps()} />
                        )}
                      </Rail>
                      <Handles>
                        {({ handles, getHandleProps }) => (
                          <div className="slider-handles">
                            {handles.map(handle => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domain}
                                getHandleProps={getHandleProps}
                              />
                            ))}
                          </div>
                        )}
                      </Handles>
                      <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                          <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                              <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                              />
                            ))}
                          </div>
                        )}
                      </Tracks>
                      <Ticks count={zoomPhase[0].active ? 10 : 20}>
                        {({ ticks }) => (
                          <div className="slider-ticks">
                            {ticks.map(tick => (
                              <Tick
                                key={tick.id}
                                tick={tick}
                                count={ticks.length}
                              />
                            ))}
                          </div>
                        )}
                      </Ticks>
                    </Slider>
                  </div>

                  <Grid container justify="center" spacing={2}>
                    <Grid item>
                      <Button
                        disabled={maxZoom}
                        variant="contained"
                        onClick={onIncreaseZoomButtonClickHandler}
                        className={visualisationStyles.buttonStyle}
                      >
                        +
                    </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        disabled={minZoom}
                        variant="contained"
                        color="primary"
                        onClick={onDecreaseZoomButtonClicked}
                        className={visualisationStyles.buttonStyle}
                      >
                        -
                    </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    onClick={getDataButtonClickedHandler}
                    className={visualisationStyles.buttonStyle}
                  >
                    Lekérdezés
                </Button>
                </Grid>

                <Grid container justify="center">
                  {loading ? (
                    <CircularProgress
                      color="primary"
                      style={{ margin: "0 auto" }}
                    />
                  ) : null}

                  {failure.error ? (
                    <p style={{ margin: "30px" }}>
                      {failure.message}
                    </p>
                  ) : null}
                </Grid>
                <Sunbrust data={sunbrustData}/>
              </div>
            </Container>
          </Box>
        </Grid>
      </VisualisationLayout>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={failure.error}
        autoHideDuration={4000}
        onClose={closeErrorModalHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorModalHandler}
          variant="error"
          message={failure.message}
        />
      </Snackbar>
    </React.Fragment>
  )
}
export default Visualisation
