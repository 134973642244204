import { navigate } from "gatsby"

export default function  define (runtime, observer) {
    let config = runtime.config;
    let fieldsMapping = config.fieldsMapping;
    const main = runtime.module();

    if(!config.data) return

    main.variable(observer("chart")).define("chart", ["partition", "data", "d3", "width", "color", "arc", "format", "radius"], function (partition, data, d3, width, color, arc, format, radius) {
            data = createDataByElasticAggregate(data);
            const root = partition(data);

            root.each(d => d.current = d);

            const svg = d3.create("svg")
                .attr("viewBox", [0, 0, width, width])
                .style("font-size", "12px")
                .style("font-family","'Lora', serif");

            const g = svg.append("g")
                .attr("transform", `translate(${width / 2},${width / 2})`);

            const path = g.append("g")
                .selectAll("path")
                .data(root.descendants().slice(1))
                .join("path")
                .attr("fill", d => {
                    while (d.depth > 1) d = d.parent;
                    return color(d.data.name);
                })
                .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
                .attr("d", d => arc(d.current));

            path.filter(d => d.children)
                .style("cursor", "pointer")
                .on("click", clicked);
            
            path.filter(d => !d.children)
                .style("cursor", "pointer")
                .on("click", plsnavigate);
            

            path.append("title")
                .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);


            const label = g.append("g")
                .attr("pointer-events", "none")
                .attr("text-anchor", "middle")
                .style("user-select", "none")
                .selectAll("text")
                .data(root.descendants().slice(1))
                .join("text")
                .attr("dy", "0.35em")
                .attr("fill-opacity", d => +labelVisible(d.current))
                .attr("transform", d => labelTransform(d.current))
                .text(d => d.data.name.length > 15 ? d.data.name.slice(0, 12) + '...' : d.data.name);

            const parent = g.append("circle")
                .datum(root)
                .attr("r", radius)
                .attr("fill", "none")
                .attr("pointer-events", "all")
                .on("click", clicked);
            
            function plsnavigate(p)  {
                //TODO: elements csúnyán néz ki
                navigate(`/elements/${p.data.elasticId}`)
            }
            
            function clicked(p) {
                parent.datum(p.parent || root);

                root.each(d => d.target = {
                    x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    y0: Math.max(0, d.y0 - p.depth),
                    y1: Math.max(0, d.y1 - p.depth)
                });

                const t = g.transition().duration(750);

                // Transition the data on all arcs, even the ones that aren’t visible,
                // so that if this transition is interrupted, entering arcs will start
                // the next transition from the desired position.
                path.transition(t)
                    .tween("data", d => {
                        const i = d3.interpolate(d.current, d.target);
                        return t => d.current = i(t);
                    })
                    .filter(function (d) {
                        return +this.getAttribute("fill-opacity") || arcVisible(d.target);
                    })
                    .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                    .attrTween("d", d => () => arc(d.current));

                label.filter(function (d) {
                    return +this.getAttribute("fill-opacity") || labelVisible(d.target);
                }).transition(t)
                    .attr("fill-opacity", d => +labelVisible(d.target))
                    .attrTween("transform", d => () => labelTransform(d.current));
            }

            function arcVisible(d) {
                return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
            }

            function labelVisible(d) {
                return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
            }

            function labelTransform(d) {
                const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
                const y = (d.y0 + d.y1) / 2 * radius;
                return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
            }

            return svg.node();
        }
    );

    function createDataByElasticAggregate(json) {

        const aggs = json.aggregations;
        const root = {"name": "Művek: ", "children": []};
        root.children = depthSearch(fieldsMapping, aggs);
        return root;
    }

    function depthSearch(fieldsMapping, currentJson) {
        const currentNode = [];

        fieldsMapping.children.forEach(currentMapping => {

            let key = currentMapping.key;
            let nameKey = currentMapping.field;

            if (typeof (currentJson[key]) !== 'undefined') {
                let currentData = currentJson[key];

                if (typeof (currentData.buckets !== 'undefined')) {
                    currentData.buckets.forEach(bucket => {
                        if (typeof (bucket.doc_count) !== 'undefined') {
                            let dataNode = {};

                            dataNode.name = bucket[nameKey];

                            if (currentMapping.count === true) {
                                dataNode.value = bucket.doc_count;
                            }

                            // ez berakja a node-ba az elasticID-t 
                            if (currentMapping.children[0].key === 'elasticid') {
                                dataNode.elasticId = bucket.elasticId.buckets[0].key
                            }                              

                            // ez andris kód, de nem tudom hogyan áll meg pont jó helyen
                            if (currentMapping.children) {
                                dataNode.children = depthSearch({children: currentMapping.children}, bucket)
                            } 


                            currentNode.push(dataNode);
                        }


                    });
                } else {
                    console.info('todo: Eset lekezelése');
                }
            }

        });

        return currentNode;

    }

    main.variable(observer("data")).define("data", ["d3"], function (d3) {
        return config.data
    });
    main.variable(observer("partition")).define("partition", ["d3"], function (d3) {
        return (
            data => {
                const root = d3.hierarchy(data)
                    .sum(d => d.value)
                    .sort((a, b) => b.value - a.value);
                return d3.partition()
                    .size([2 * Math.PI, root.height + 1])
                    (root);
            }
        )
    });

    main.variable(observer("color")).define("color", ["d3", "data"], function (d3, data) {

        data = createDataByElasticAggregate(data);
        const keys = [...data.children.keys()];
        keys.push(keys.length);

        const generator =
            d3.scaleLinear()
                .domain(keys)
                .range([
                        d3.color('#e8b96b'),
                        d3.color('#042f4b'),
                        d3.color('#193E54'),
                        d3.color('#596E79'),
                        d3.color('#333333')
                    ]
                );

        const range = d3.range(keys.length).map(generator);

        return (d3.scaleOrdinal(range))
    });
    main.variable(observer("format")).define("format", ["d3"], function (d3) {
        return (
            d3.format(",d")
        )
    });
    main.variable(observer("width")).define("width", function () {
        return (
            932
        )
    });
    main.variable(observer("radius")).define("radius", ["width"], function (width) {
        return (
            width / 6
        )
    });
    main.variable(observer("arc")).define("arc", ["d3", "radius"], function (d3, radius) {
        return (
            d3.arc()
                .startAngle(d => d.x0)
                .endAngle(d => d.x1)
                .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
                .padRadius(radius * 1.5)
                .innerRadius(d => d.y0 * radius)
                .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))
        )
    });
    main.variable(observer("d3")).define("d3", ["require"], function (require) {
        return (
            require("d3@5")
        )
    });





    return main;
}
