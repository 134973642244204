import {Runtime, Inspector} from '@observablehq/runtime/dist/runtime'
import define from './zoomable-sunburst'

export default (config) => {
    const runtimeObject = new Runtime();

    console.log(config.targetId)
    console.log(document.getElementById(config.targetId))

    runtimeObject.config = config;
    runtimeObject.module(define, name => {
        return new Inspector(document.getElementById(config.targetId));
    });
    //document.getElementById(config.targetTitle).innerHTML = config.title;
}
